/* eslint-disable functional/immutable-data */
import { useMutation, useQuery } from '@apollo/react-hooks';
import Link from 'next/link';
import Router from 'next/router';
import React, { useState } from 'react';

import { LOCAL_LOGOUT } from '../lib/mutations';
import { ME } from '../lib/queries';
import routes from '../lib/routes';
import t from '../lib/translations';
import BasicModal from './BasicModal';
import BurguerMenuIcon from './icons/BurguerMenuIcon';
import LogoutIcon from './icons/LogoutIcon';

const Header = ({ onToggle }: { readonly onToggle: () => void }): JSX.Element => {
  useQuery(ME); // Use to check auth refresh
  const [localLogout] = useMutation(LOCAL_LOGOUT);
  const [showModal, setShowModal] = useState(false);

  const onClickLogout = async (): Promise<void> => {
    await localLogout();
    Router.push(routes.LOGIN);
  };

  const onShowModal = (): void => setShowModal(!showModal);
  const onConfirm = (): void => {
    onShowModal();
    onClickLogout();
  };
  const onCancel = (): void => onShowModal();

  return (
    <>
      <nav className="bg-gray-900 py-2">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center">
              <BurguerMenu onToggle={onToggle} />
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <Link
                  href={routes.HOME}
                  children={
                    <a className="text-link-white lg:block w-auto sm:ml-10" href="#">
                      <img
                        alt="logo"
                        src="/logo.png"
                        className="inline-block align-top ml-3 mr-4 h-12 w-12"
                      />
                    </a>
                  }
                />
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  <Link
                    href={routes.HOME}
                    children={
                      <a className="text-link-white" href="#">
                        <span className="text-3xl-semibold text-green-eveningSea">
                          {t.PRODUTOR_TITLE}
                        </span>
                        <span className="text-3xl-semibold text-orange-chocolate m-0">
                          {t.CARD_TITLE}
                        </span>
                        <span className="text-green-eveningSea mr-3 ml-3">-</span>
                        <span className="text-lg text-green-eveningSea">{t.ADMINISTRATOR}</span>
                      </a>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="flex items-center mr-2 cursor-pointer" onClick={onShowModal}>
                <span className="text-gray-600 text-sm font-thin mr-1">{t.LOGOUT}</span>
                <LogoutIcon />
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Modal Confirm Logout */}
      {showModal && (
        <BasicModal
          onConfirm={onConfirm}
          onCancel={onCancel}
          title={t.LOGOUT}
          content={t.info.CONFIRM_LOGOUT}
        />
      )}
    </>
  );
};

const BurguerMenu = ({ onToggle }: { readonly onToggle: () => void }): JSX.Element => {
  return (
    <div className="block">
      <button
        onClick={onToggle}
        id="nav-toggle"
        className="flex items-center px-3 py-2 rounded text-gray-500 hover:text-white hover:border-white focus:outline-none">
        <BurguerMenuIcon />
      </button>
    </div>
  );
};

export default Header;
