import React from 'react';

import { AlertProps } from '../lib/types';
import InfoIcon from './icons/InfoIcon';

const getColor = (variant: string): string => {
  switch (variant) {
    case 'success':
      return 'teal';
    case 'danger':
      return 'red';
    case 'warning':
      return 'yellow';
    case 'info':
      return 'blue';
    default:
      return 'teal';
  }
};

const Alert = ({ variant, heading, children, style }: AlertProps): JSX.Element => {
  const color = getColor(variant);
  return (
    <div
      className={`bg-${color}-100 border-t-4 border-${color}-500 z-20 rounded-b text-${color}-900 px-4 py-3 shadow-md mb-2 min-h-20 absolute right-0 mr-2 w-2/3 sm:w-1/4`}
      role="alert"
      style={style}>
      <div className="flex">
        <div className="py-1">
          <InfoIcon color={color} />
        </div>
        <div>
          {heading && <p className="font-bold">{heading}</p>}
          <div className="text-sm">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
