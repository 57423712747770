import { useQuery } from '@apollo/react-hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { ME } from '../lib/queries';
import routes from '../lib/routes';
import t from '../lib/translations';
import CloseIcon from './icons/CloseIcon';
import UserIcon from './icons/UserIcon';

const items = [
  { href: routes.HOME, title: t.HOME },
  { href: routes.FARMERS, title: t.MANAGEMENT_FARMER },
  { href: routes.PARTNERS, title: t.MANAGEMENT_PARTNER },
  { href: routes.GUARANTORS, title: t.MANAGEMENT_GUARANTOR },
  { href: routes.BUSINESS, title: t.MANAGEMENT_BUSINESS },
  { href: routes.CONTROL_SHEET_CREATE, title: t.TRANSACTION },
  { href: routes.RESET_PASSWORD, title: t.SETTINGS },
];

const Sidebar = ({ onToggle }: { readonly onToggle: () => void }): JSX.Element => {
  const { data } = useQuery(ME);
  const router = useRouter();
  const administrator = data?.me?.name;
  const partner = data?.me?.partner?.administrator;
  const guarantor = data?.me?.guarantor?.administrator;

  return (
    <>
      {/* Desktop Sidebar */}
      <div id="sidebar" className="sidebar-container div-content-sidebar hidden sm:block">
        <ul>
          <li className="sidebar-user">
            <UserIcon size="12" color="text-gray-500" />
            {administrator && <div className="text-gray-500">{administrator}</div>}
            {!administrator && <div className="text-gray-500">{partner ? partner : guarantor}</div>}
          </li>

          <div className="sidebar-links">
            {items.map((item) => (
              <li key={item.href}>
                <Link href={item.href}>
                  <a
                    className={`sidebar-a ${router?.pathname.includes(item.href) ? 'active' : ''}`}>
                    <span className="sidebar-title">{item.title}</span>
                  </a>
                </Link>
              </li>
            ))}
          </div>
        </ul>
      </div>

      {/* Mobile Sidebar */}
      <div className="fixed overflow-hidden z-10 block sm:hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-y-0 left-0 pr-10 max-w-full flex">
            <div className="w-screen max-w-md ">
              <div className="h-full flex flex-col py-6 bg-gray-900 shadow-xl overflow-y-scroll">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <div className="text-link-white w-auto flex flex-row">
                      <img
                        alt="logo"
                        src="/logo.png"
                        className="inline-block align-top mr-4 h-12 w-12"
                      />
                      <div className="flex flex-col">
                        <div className="flex flex-inline">
                          <span className="text-xl text-green-eveningSea">{t.PRODUTOR_TITLE}</span>
                          <span className="text-xl text-orange-chocolate m-0">{t.CARD_TITLE}</span>
                        </div>
                        <span className="text-xs text-green-eveningSea">{t.ADMINISTRATOR}</span>
                      </div>
                    </div>

                    <div className="ml-3 mt-2 h-7 flex items-center">
                      <button
                        onClick={onToggle}
                        className="rounded-md text-gray-100 hover:text-gray-500 focus:outline-none">
                        <CloseIcon color="gray" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mt-6 relative flex-1">
                  <ul>
                    <li className="sidebar-user">
                      <UserIcon size="12" color="text-gray-500" />
                      {administrator && <div className="text-gray-500">{administrator}</div>}
                      {!administrator && (
                        <div className="text-gray-500">{partner ? partner : guarantor}</div>
                      )}
                    </li>

                    <div className="sidebar-links">
                      {items.map((item) => (
                        <li key={item.href}>
                          <Link href={item.href}>
                            <a
                              onClick={onToggle}
                              className={`sidebar-a ${
                                router?.pathname.includes(item.href) ? 'active' : ''
                              }`}>
                              <span className="sidebar-title">{item.title}</span>
                            </a>
                          </Link>
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
