import { useQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect } from 'react';

import helpers from '../lib/helpers';
import { LOCAL_SET_FLASH } from '../lib/mutations';
import { LOCAL_GET_FLASH } from '../lib/queries';
import t from '../lib/translations';
import { FlashType } from '../lib/types';
import Alert from './Alert';

const TIME_OUT = 5000;
const FlashAlert = (): JSX.Element | null => {
  const [setFlash] = useMutation(LOCAL_SET_FLASH);
  const { data } = useQuery(LOCAL_GET_FLASH);
  const flash = data?.localGetFlash;

  useEffect(() => {
    setTimeout(() => {
      setFlash({});
    }, TIME_OUT);
  }, [data]);

  if (flash?.type === FlashType.success) {
    return (
      <Alert variant="success" heading={t.success.SUCCESS}>
        {flash?.payload?.message}
      </Alert>
    );
  }

  if (flash?.type === FlashType.info) {
    return (
      <Alert variant="warning" heading={t.info.NO_DATA}>
        {flash?.payload?.message}
      </Alert>
    );
  }

  if (flash?.type === FlashType.error) {
    const error = flash?.payload && helpers.parseError(JSON.parse(flash?.payload));

    return (
      <Alert variant="danger" heading={t.error.ERROR}>
        {error?.graphQLErrors?.map((err, index) => (
          <div key={index}>
            {helpers.getKeyValue<any, any>(err.message)(t.error) || err.message}
          </div>
        ))}
      </Alert>
    );
  }

  return null;
};

export default FlashAlert;
