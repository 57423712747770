import React from 'react';

import t from '../lib/translations';
import { NoDataProps } from '../lib/types';
import NoDataIcon from './icons/NoDataIcon';

const NoData = ({ icon, msg = t.info.NO_DATA }: NoDataProps): JSX.Element => {
  return (
    <div className="bg-gray-100  h-full flex flex-col justify-center items-center">
      <NoDataIcon {...icon} />
      <div className="text-sm font-thin text-gray-800">{msg}</div>
    </div>
  );
};

export default NoData;
