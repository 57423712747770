import { useMutation, useQuery } from '@apollo/react-hooks';
import Head from 'next/head';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import helpers from '../lib/helpers';
import { SET_SIDEBAR } from '../lib/mutations';
import { IS_SIDEBAR_OPEN, LOCAL_GET_LOADING } from '../lib/queries';
import { ContainerProps } from '../lib/types';
import FlashAlert from './FlashAlert';
import Header from './Header';
import Loading from './Loading';
import NoData from './NoData';
import Sidebar from './Sidebar';

const Container = ({ head, children, loading = false, data }: ContainerProps): JSX.Element => {
  const { data: dataQuery } = useQuery(LOCAL_GET_LOADING);
  const noData = helpers.isEmpty(data) && !loading;
  const isLoading = (loading && !noData) || dataQuery?.localGetLoading;

  const { data: sidebarData } = useQuery(IS_SIDEBAR_OPEN);
  const [setSideBar] = useMutation(SET_SIDEBAR);

  const onToggle = (): void => {
    setSideBar({ variables: { open: !sidebarData?.isSidebarOpen } });
  };

  return (
    <div className="div-container">
      <Head>
        <title>{head}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Header onToggle={onToggle} />

      <div className="div-content">
        <div id="div-content-sidebar">
          {sidebarData?.isSidebarOpen && <Sidebar onToggle={onToggle} />}
        </div>
        <div className="div-children">
          <FlashAlert />
          {noData && <NoData />}
          {isLoading && <Loading />}
          {children ?? children}
          {process.browser && <ReactTooltip />}
        </div>
      </div>
    </div>
  );
};

export default Container;
