import React from 'react';
import { Spinner } from 'react-bootstrap';

import t from '../lib/translations';
import { BasicModalProps } from '../lib/types';
import CloseIcon from './icons/CloseIcon';

const BasicModal = ({
  onConfirm,
  onCancel,
  content,
  title,
  loading,
  type = 'default',
}: BasicModalProps): JSX.Element => {
  return (
    <div className="tw-modal-container">
      <div className="modal-overlay tw-modal-bg" />

      <div className="modal-container tw-modal-content md:max-w-md">
        <div className="modal-content py-4 text-left px-6">
          <div className="flex justify-between items-center pb-3">
            <p className="text-2xl font-bold">{title}</p>
            <div className="modal-close cursor-pointer z-50" onClick={onCancel}>
              <CloseIcon />
            </div>
          </div>

          {content}

          <div className="tw-modal-buttons-container ">
            <button className={`tw-modal-button-confirm-${type}`} onClick={onConfirm}>
              {loading ? <Spinner size="sm" animation="border" /> : t.CONFIRM}
            </button>
            <button className={`tw-modal-button-cancel-${type} modal-close`} onClick={onCancel}>
              {t.CANCEL}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicModal;
