import { ApolloError } from 'apollo-boost';
import { ChangeEventHandler, CSSProperties, FormEventHandler, MouseEventHandler, Ref } from 'react';
import { FieldErrors } from 'react-hook-form';

import { User, Partner, Farmer, Guarantor, Transaction } from '../generated/types';

export type LoginFormProps = {
  readonly email: string;
  readonly password: string;
  readonly loading: boolean;
  readonly onSubmit: any;
  readonly onChange: any;
  readonly errors: readonly any[];
  readonly error?: ApolloError;
};

export type ContainerProps = {
  readonly head: string;
  readonly children: any;
  readonly loading?: boolean;
  readonly data?: any;
};

export type IconProps = {
  readonly color?: string;
  readonly size?: string;
  readonly width?: string;
  readonly height?: string;
  readonly styles?: string;
  readonly onClick?: any;
};

export type ButtonGroupProps = {
  readonly register?: any;
  readonly approve?: any;
  readonly report?: any;
};

export type SearchInputProps = {
  readonly onChange: any;
  readonly placeholder?: string;
  readonly value?: string;
  readonly data?: any;
  readonly filterData?: any;
};

export type ListItem = {
  readonly id: string;
  readonly name?: string;
  readonly rg?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly dateOfBirth?: string;
  readonly income?: string;
  readonly gender?: string;
  readonly limit?: string;
  readonly userId?: string;
  readonly user?: User;
};

export type ListItemProps = {
  readonly item: ListItem;
  readonly actions: ContentListActions;
  readonly onClickItem?: any;
  readonly dataType?: 'farmers' | 'guarantors' | 'partners';
  readonly query?: any;
};

export type ListProps = {
  readonly data: readonly ListItem[];
  readonly loading?: boolean;
  readonly error?: ApolloError;
  readonly actions: ContentListActions;
  readonly onClickItem?: any;
  readonly dataType?: 'farmers' | 'guarantors' | 'partners';
  readonly query?: any;
};

export type ContentListActions = {
  readonly onClickAction?: Function;
  readonly approve?: boolean;
  readonly remove?: boolean;
  readonly edit?: boolean;
  readonly changeCreditLimit?: boolean;
  readonly resetPassword?: boolean;
};

export type ContentListActionsArgs = {
  readonly limit: string;
};

export type ListItemActionsProps = {
  readonly actions: ContentListActions;
  readonly item: ListItem;
  readonly dataType: 'farmers' | 'guarantors' | 'partners';
  readonly query?: any;
};

export type ContentListProps = {
  readonly buttons: ButtonGroupProps;
  readonly dataType: 'farmers' | 'guarantors' | 'partners';
  readonly query: any;
  readonly actions: ContentListActions;
  readonly head: string;
};

export type ContentViewProps = {
  readonly data?: any;
  readonly title?: any;
  readonly loading?: any;
};

export type BasicModalProps = {
  readonly onConfirm: any;
  readonly onCancel: any;
  readonly title?: any;
  readonly content?: any;
  readonly loading?: any;
  readonly type?: 'danger' | 'default';
};

export type FormFragment = {
  readonly register: any;
  readonly errors: any;
  readonly isEdit?: boolean;
  readonly guarantors?: readonly Guarantor[];
  readonly phoneNumber?: any;
  readonly onChange?: any;
};

export type UserFormProps = {
  readonly loading: boolean;
  readonly onSubmit: any;
  readonly buttonTitle?: string;
  readonly cancelButton?: string;
  readonly apolloError?: any;
};

export type GuarantorFormProps = UserFormProps & {
  readonly guarantor?: Guarantor;
};

export type FarmerFormProps = UserFormProps & {
  readonly loading?: boolean;
  readonly farmer?: Farmer;
  readonly onChange?: any;
  readonly phoneNumber?: string;
};

export type PartnerFormProps = UserFormProps & {
  readonly partner?: Partner;
};

export type IdentifyFormProps = {
  readonly phone: string;
  readonly document: string;
  readonly loading: boolean;
  readonly error?: ApolloError;
  readonly onSubmit: FormEventHandler;
  readonly onChangeDocument: ChangeEventHandler;
  readonly onChangePhone: ChangeEventHandler;
};

export type NoDataProps = {
  readonly icon?: IconProps;
  readonly msg?: string;
};

export type AlertProps = {
  readonly variant: 'success' | 'danger' | 'warning';
  readonly heading?: string;
  readonly style?: CSSProperties;
  readonly children: any;
};

export type BasicButtonProps = {
  readonly children: string;
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly type?: 'submit' | 'reset' | 'button';
  readonly onClick?: MouseEventHandler;
  readonly testId?: string;
  readonly className?: string;
};

export type BasicTextInputProps = {
  readonly name: string;
  readonly label: string;
  readonly type?: string;
  readonly step?: string | number;
  readonly min?: string | number;
  readonly max?: string | number;
  readonly placeholder?: string;
  readonly errors?: FieldErrors<any>;
  readonly inputRef?: Ref<any>;
  readonly disabled?: boolean;
  readonly onChange?: any;
  readonly value?: string;
};

export type ErrorInputContainerProps = {
  readonly errors: any;
  readonly name: string;
};

export type SelectProps = {
  readonly name: string;
  readonly label?: string;
  readonly children: any;
  readonly errors?: { readonly [key: string]: Error };
  readonly inputRef?: Ref<any>;
  readonly disabled?: boolean;
  readonly onChange?: any;
  readonly defaultValue?: string;
  readonly value?: string;
  readonly className?: string;
  readonly containerClassName?: string;
  readonly labelClassName?: string;
};

export type ErrorAlertContainerProps = {
  readonly error?: ApolloError;
  readonly className?: string;
};

export type CompanyFragmentProps = FormFragment;

export type FarmerFragmentProps = FormFragment;

export type PartnerFragmentProps = FormFragment & {
  readonly categories?: readonly any[];
  readonly defaultCategory?: string;
};

export type TransactionFormProps = UserFormProps & {
  readonly onChangePassword: any;
};

export enum FlashType {
  success,
  error,
  info,
}

export type TableProps = {
  readonly columns: any;
  readonly data: any;
  readonly className?: any;
};

export type RefundModalProps = {
  readonly transaction: Transaction;
  readonly handleClose: () => void;
};

export type CurrencyInputProps = {
  readonly className?: string;
  readonly max?: number;
  readonly onValueChange: (value: number) => void;
  readonly style?: CSSProperties;
  readonly value: number;
};

export type ControlSheetReport = Transaction & {
  readonly correspondingLimit: number;
};
