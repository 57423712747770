import React from 'react';

import { IconProps } from '../../lib/types';

const LogoutIcon = ({
  width = '6',
  height = '6',
  color = 'gray-600',
  styles,
}: IconProps): JSX.Element => {
  return (
    <svg
      className={`h-${height} w-${width} text-${color} ${styles}`}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />{' '}
      <path d="M7 12h14l-3 -3m0 6l3 -3" />
    </svg>
  );
};

export default LogoutIcon;
